
$prefix: '';
// body
$body-bg: #f8fafc;
// colors
$color-dark: #333;
$color-dark-2: #444;
$color-gray: #767676;
$color-white: #fff;
$color-red: #e7202a;
$color-red-2: #b44e53;
$color-gray-dark: #dedede;
$color-gray-light: #f5f5f5;
$color-green: #28b16d;
$color-green-2: #26a868;
$color-blue: #017efa;
$color-blue-2: #0475e5;
$color-yellow: #ffac45;
$color-yellow-2: #eb9c3a;
// border
$border-radius: 5px;
$border-color: #dedede;
// shadow
$box-shadow: 5px 4px 15px 2px rgba(0,0,0,.13);
