.blog-list {
    display: flex;
    position: relative;
    width: 100%;
    background: $color-white;
    border-radius: $border-radius;
    align-items: center;
	padding: 0.5rem;
    justify-content: flex-start;
    .blog-list-thumb {
        height: 100%;
        flex:1;
    }
    .blog-list-description {
        padding: 0 1rem;
        display: flex;
        flex-direction: column;
        flex: 1;
        .blog-list-title {
            .post-title {
                line-height: 20px;
                text-transform: none;
                a {
                    color: $color-dark;
                    font-size: 18px;
                    transition: all 0.6s ease 0s;
                    &:hover {
                        color: $color-red;
                    }
                }
            }
        }
        .blog-list-summary {
            p {
                color: $color-dark;
                line-height: 18px;
                margin-bottom: 0px;
            }
        }
        .blog-list-footer {
            border-top: 1px solid $border-color;
            margin-top: 10px;
            padding-top: 5px;
            .post-footer {
                color: $color-dark;
            }
        }
    }
}
.single-blog-details {
    margin-bottom: 20px;
    .post-thumb {
        margin-bottom: 20px;
        img {
            height: auto;
            width: 100%;
            border-radius: $border-radius;
        }
    }
    .post-title {
        h1 {
            font-weight: 600;
            font-size: 18px;
            margin-bottom: 20px;
            text-transform: none;
        }
        .date-time {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            color: $color-dark;
            font-weight: 400;
            font-size: 15px;
            gap: 10px;
            margin-bottom: 20px;
            padding-left: 0;
            li {
                line-height: 1;
                &.dot {
                    width: 4px;
                    height: 4px;
                    background-color: $color-dark;
                    border-radius: 100%;
                }
            }
        }
    }
    .post-content {
        p {
            font-weight: 400;
            font-size: 14px;
            color: $color-dark;
            line-height: 1.5;
            margin-bottom: 0;
        }
    }
}