.auth-modal {
    width: 450px;
    max-width: 450px;
    .auth-form {
        .form-group {
            position: relative;
            margin-bottom: 20px;
            label {
                font-size: 12px;
                font-weight: 500;
                color: $color-dark;
            }
            .form-control {
                height: 52px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                border:2px solid $color-gray-light;
                font-weight: 500;
                font-size: 13px;
                color: $color-dark-2;
            }
            .btn {
                .btn-modal-login {
                    background: $color-blue;
                    width: 100%;
                    height: 52px;
                    border-radius: 5px;
                    font-weight: 600;
                    font-size: 18px;
                    color: $color-white;
                }
            }
        }
    }
}
.location-modal, .location {
    max-width: 700px !important;
    // .modal-content {
        .modal-body, .offcanvas-body {
            padding: 1rem;
            .location-map {
                width: 100%;
                height: 400px;
            }
        }
        .btn-set-location {
            margin-top: 20px;
            height: 40px;
        }
    // }
}
.modal-dialog {
    &.filter-popup {
        max-width: 900px;
    }
}
.modal-content {
    .modal-header {
        text-align: left;
        display: flex;
        justify-content: left;
        h5 {
            color: $color-dark;
            margin: 0;
            font-size: 18px;
            text-transform: unset;
        }
    }
    .modal-body {
        padding: 1rem;
        &.padding-x-none {
            padding-right: 0;
            padding-left: 0;
        }
        .title-section {
            padding: 10px 5px;
            text-align: center;
            font-weight: normal;
            font-size: 16px;
            background-color: $color-gray-light;
            margin-bottom: 15px;
        }
        .text-section {
            padding: 0 15px;
            margin-bottom: 15px;
            p {
                font-size: 14px;
                color: $color-dark;
                margin-bottom: 5px;
            }
            .small {
                color: $color-dark-2;
                font-size: 12px;
            }
        }
        .checkbox-section {
            padding: 0 15px;
            margin-bottom: 5px;
            .checkbox-list {
                border-bottom: 1px solid $color-gray-light;
                padding-bottom: 10px;
                margin-bottom: 10px;
                .form-check {
                    font-size: 14px;
                    .form-check-input {
                        height: 20px;
                        width: 20px;
                        margin-right: 10px;
                        margin-top: 0;
                    }
                }
                .form-description {
                    font-size: 12px;
                }
                &.border-none {
                    border: none;
                }
            }
        }
        .input-section {
            padding: 0 15px;
            border-bottom: 1px solid $color-gray-light;
            padding-bottom: 10px;
            margin-bottom: 10px;
        }
        .divider {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 2rem 0 2rem;
            font-weight: 500;
            font-size: 14px;
            color: $color-gray-dark;
        }
        .modal-flex-item {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }
        h5 {
            text-transform: unset;
        }
        .form-select {
            font-size: 14px;
        }
        .result-message {
            padding: 0 15px;
            text-align: center;
            color: $color-red;
        }
    }
    .modal-footer {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 13px;
        .footer-text {
            font-size: 12px;
            color: $color-dark-2;
            text-align: left;
        }
    }
    span {
        &.modal-close {
            position: absolute;
            right: 15px;
            top: 17px;
            width: 26px;
            height: 26px;
            border-radius: 50%;
            background: $color-gray-light;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            cursor: pointer;
            z-index: 1;
        }
    }
}
.modal-open {
    overflow: hidden !important;
	padding-right: 0 !important;
}