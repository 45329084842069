* {
    outline: none;
}
body {
	background: $color-white;
	color: $color-dark;
	font-size: 14px;
	font-family: 'Helvetica', sans-serif;
	margin: 0;
	overflow-x: hidden !important;
	font-weight: normal;
}
html {
    position: relative;
    min-height: 100%;
    background: $color-white;
}
a {
    color: $color-blue;
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
    &:hover, &:focus {
        text-decoration: none;
        color: $color-blue-2;
    }
}
section {
	padding: 20px 0 40px;
    &.min {
        padding: 20px 0 20px;
    }
    &.reset {
        padding: 0;
    }
    &.mb-none {
        padding: 20px 0 0;
    }
}
p {
    line-height: 1.8;
}


// container
@media (min-width:576px) {
    .container, .container-sm {
        max-width: 540px
    }
}
@media (min-width:768px) {
    .container, .container-md, .container-sm {
        max-width:720px
    }
}
@media (min-width:992px) {
    .container, .container-lg, .container-md, .container-sm {
        max-width: 900px
    }
}
@media (min-width:1200px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1000px
    }
}
@media (min-width:1400px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1100px
    }
}



.border-none {
    border: unset !important;
}
.nav-link {
	color: $color-blue;
}
.img-responsive {
    width: 100%;
    height: auto;
    display: inline-block;
}
.img-rounded {
    border-radius: $border-radius;
}
.title {
    color: $color-dark;
    margin-bottom: 20px;
    &.mb-10 {
        margin-bottom: 10px;
    }
    h1 {
        color: $color-dark;
        font-weight: 600;
        font-size: 24px;
        text-transform: none;
    }
    .title h4 {
        font-weight: 600;
    }
}
.title-grid {
    color: $color-dark;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 15px;
    h4 {
        font-size: 22px;
        color: $color-dark;
        margin-bottom: 0;
        text-transform: none;
    }
    h5 {
        font-weight: 600;
        &.title-content {
            margin-top: 5px;
            font-weight: 400;
        }
    }
    a {
        font-weight: normal;
        transition: .3s linear;
        font-weight: normal;
        background-color: $color-gray-light;
        border: 1px solid $border-color;
        color: $color-dark;
        border-radius: 100px;
        padding: 5px 10px;
        &:hover {
            border-color: $color-dark;
        }
    }
    &.border-top {
        margin-top: 10px;
        padding-top: 15px;
        border-top: 1px solid $border-color !important;
    }
}
.ajax-loader {
    visibility: hidden;
    background-color: rgba(255,255,255,0.7);
    position: absolute;
    z-index: 1111 !important;
    width: 100%;
    height: 100%;
    cursor: wait;
    &:after {
        display: inline-block;
        position: relative;
        content: url('https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/0.16.1/images/loader-large.gif');
        top: 50%;
        left: 50%;
    }
}
.fixed-section {
    overflow-x: auto;
    position: sticky;
    max-height: 90vh;
    top: 120px;
}
.max-iframe {
    max-height: 450px !important;
}
.responsive-iframe {
    position: relative;
    padding-bottom: 56.25%;
    overflow: hidden;
    max-width: 100%;
    iframe {
        height: 450px;
    }
}
.p-xy-10 {
    padding: 10px;
}
.p-xy-20 {
    padding: 20px;
}
.p-xy-30 {
    padding: 30px;
}
hr {
    &.hr-blurry {
        border-color: $border-color;
    }
}










.explore-content {
    h1 {
        font-size: 28px;
        font-weight: 600;
        color: $color-dark;
        margin-bottom: 20px;
    }
    div, p {
        color: $color-dark;
        font-size: 16px;
    }
}


// custom box
.custom-box {
    position: relative;
    display: flex;
    width: 100%;
    .box {
        display: flex;
        flex-direction: column;
        flex: 0 0 33.333333%;
        width: 33.333333%;
        padding: 1.5rem;
        text-align: center;
        .icon {
            font-size: 44px;
            color: $color-red;
            margin-bottom: 0.5rem;
        }
        .caption {
            p {
                margin-bottom: 0;
                font-weight: 500;
                font-size: 16px;
                color: $color-dark;
                a {
                    color: $color-dark;
                    transition: .3s linear;
                    &:hover {
                        color: $color-red;
                    }
                }
            }
        }
    }
}
.contact-form {
    margin-top: 20px;
    h2 {
        font-size: 28px;
        font-weight: 600;
        color: $color-dark;
        text-transform: unset;
    }
}

.g-recaptcha {
    display: inline-block;
    margin: 0 auto;
}