.full-search {
    background: $color-white;
    padding: 10px 0;
    position: relative;
    width: 100%;
    .classic-search-box {
        display: flex;
        align-items: center;
        position: relative;
        margin: 0;
    }
    .form-group {
        margin: 0;
        &.full {
            flex: 1;
            width: 100%;
        }
        .input-with-icon {
            display: flex;
            align-items: center;
            position: relative;
            .form-control {
                padding-left: 35px;
            }
            i, img {
                position: absolute;
                left: 10px;
                color: $color-gray;
            }
            i {
                z-index: 59;
            }
        }
    }
    .form-control {
        height: 48px !important;
        align-items: center;
        justify-content: center;
        width: 100%;
        font-size: 15px;
        line-height: 35px;
        border-radius: $border-radius;
        border: 1px solid $border-color;
        &:focus {
            box-shadow: 0 0px 0 2px $box-shadow;
        }
    }
    .btn {
        height: 48px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: $color-white;
        background-color: $color-green;
        border: 1px solid $color-green;
        border-radius: $border-radius;
        &:hover {
            background-color: $color-green-2;
            border: 1px solid $color-green-2;
            color: $color-white;
        }
        i {
            margin-right: 5px;
        }
        &.btn-filter {
            padding: 5px 10px;
            width: 100%;
        }
    }
    .nice-select {
        .list {
            box-shadow: none;
            border: 1px solid $border-color;
        }
    }
    .search-button {
        display: flex;
        align-items: center;
        justify-content: space-around;
        .form-group {
            width: 48%;
            flex: 50%;
            position: relative;
            display: flex;
        }
    }

    .dropdown-filter {
        &.price-filter, &.more-filter {
            z-index: 50;
            .btn-filter {
                -webkit-transition: all 0.15s ease-in-out;
                transition: all 0.15s ease-in-out;
                &:hover {
                    cursor: pointer;
                }
            }
            .open-filter {
                width: 300px;
                left: 0;
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                flex-direction: column;
                gap: 30px;
                padding: 20px;
                border: 1px solid $border-color;
                border-radius: $border-radius;
                background-color: $color-white;
                box-shadow: $box-shadow;
                position: absolute;
                top: 100%;
                right: 0;
                opacity: 0;
                visibility: hidden;
                &.active {
                    top: 116%;
                    z-index: 60;
                    opacity: 1;
                    visibility: visible;
                }
                .title {
                    font-size: 15px;
                    font-weight: 500;
                    line-height: 18px;
                    margin-bottom: 10px;
                }
                .grid-2 {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 20px 20px;
                    width: 100%;
                    .form-control {
                        padding-left: 15px;
                    }
                }
            }
        }
    }
}
.filter-popup {
    .modal-header {
        border-bottom: 1px solid $border-color;
        justify-content: flex-start;
        h4 {
            font-size: 20px;
            margin-bottom: 0;
        }
    }
    .modal-body {
        .form-check-touch {
            text-align: center;
            display: flex;
            input[type="radio"] {
                display: none;
                &:checked + label {
                    background-color: $color-green;
                    color: $color-white;
                }
            }
            label {
                display: inline-block;
                background-color: $color-white;
                padding: 8px 10px;
                font-size: 16px;
                cursor: pointer;
                width: 50%;
                text-align: center;
                border: 1px solid $color-green;
                margin-bottom: 0;
                &.first {
                    border-radius: 0.4rem 0 0 0.4rem;
                }
                &.last {
                    border-radius: 0 0.4rem 0.4rem 0;
                }
            }
        }
        .form-box {
            .form-group {
                margin-top: 15px;
            }
        }
    }
}