.card {
    border: 1px solid $color-gray-light;
    margin-bottom: 30px;
    border-radius: 0.5rem;
    overflow: hidden;
    background: $color-white;

    .card-header {
        background: $color-gray-light;
        padding: 0.8rem 1rem;
        border-color: $color-gray-light;
        display: flex;
        align-items: center;
        justify-content: space-between;

        h4, h5, h6 {
            font-size: 16px;
            font-weight: 600;
            margin: 0;
        }
    }

    .form-group {
        display: flex;

        label {
            padding-top: calc(.375rem + 2px);
            padding-bottom: calc(.375rem + 2px);
            margin-bottom: 0;
            font-size: inherit;
            line-height: 1.5;
        }

        textarea {
            height: 80px;
        }
    }

    hr {
        color: $color-gray-light;
    }

    .btn {
        height: 40px;
        margin-top: 15px;
    }
}



