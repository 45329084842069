.pagination-nav {
    margin-top: 20px;
    text-align: center;
}
.page-link {
    margin: 0 10px 0 10px;
    border-radius: 0.4rem;
    color: #{$color-dark};
    &:hover {
        color: #{$color-green};
    }
    &.active, .active > & {
        background-color: #{$color-green};
        border-color: #{$color-green};
        &:hover {
            color: #{$color-white};
        }
    }
}