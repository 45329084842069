.btn {
	color: $color-white;
    font-size: 14px;
	padding: 10px 20px;
	height: 40px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	-webkit-transition: all ease 0.4s;
	-o-transition: all ease 0.4s;
	transition: all ease 0.4s;
	border-radius: 0.4rem;
	border: 1px solid transparent;
    &:focus, &:hover {
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    &.btn-lg {
        padding: 1em 1.5em;
        height: 50px;
        font-size: 16px;
    }
    &.btn-md {
        padding: 1em 1.5em;
        height: 45px;
        font-size: 0.8rem;
    }
    &.btn-sm {
        padding: .25rem .5rem;
        height: 35px;
        font-size: 12px;
    }
    &.btn-xs {
        padding: .2rem .5rem;
        height: 30px;
        font-size: 10px;
    }
    &.btn-rounded {
        border-radius: 50px !important;
        -webkit-border-radius: 50px !important;
    }
    &.btn-arrow {
        position: relative;
    }
    &.btn-default {
        font-size: 14px;
        height: 40px;
        background: $color-gray-light;
        border-color: $color-gray-dark;
        color: $color-dark;
        &:hover, &:focus, &:active {
            background: $color-gray-dark !important;
            border-color: $color-gray-dark !important;
            color: $color-dark !important;
        }
    }
    &.btn-success {
        font-size: 14px;
        height: 40px;
        background: $color-green;
        border-color: $color-green;
        color: $color-white;
        &:hover, &:focus, &:active {
            background: $color-green-2 !important;
            border-color: $color-green-2 !important;
            color: $color-white !important;
        }
        &:disabled {
            background: $color-gray !important;
            border-color: $color-gray !important;
        }
    }
    &.btn-outline-success {
        font-size: 14px;
        height: 40px;
        background: $color-white;
        border-color: $color-green;
        color: $color-green;
        &:hover, &:focus, &:active {
            background: $color-green-2 !important;
            border-color: $color-green-2 !important;
            color: $color-white !important;
        }
        &:disabled {
            background: $color-gray-dark !important;
            border-color: $color-gray-dark !important;
        }
    }
    &.btn-danger {
        background: $color-red;
        border-color: $color-red;
        color: $color-white;
        &:hover, &:focus, &:active {
            background: $color-red-2 !important;
            border-color: $color-red-2 !important;
            color: $color-white !important;
        }
        &:disabled {
            background: $color-gray !important;
            border-color: $color-gray !important;
        }
    }
    &.btn-outline-danger {
        background: $color-white;
        border-color: $color-red;
        color: $color-red;
        &:hover, &:focus, &:active {
            background: $color-red-2 !important;
            border-color: $color-red-2 !important;
            color: $color-white !important;
        }
        &:disabled {
            background: $color-gray-dark !important;
            border-color: $color-gray-dark !important;
        }
    }
    &.btn-warning {
        background: $color-yellow;
        border-color: $color-yellow;
        color: $color-white;
        &:hover, &:focus, &:active {
            background: $color-yellow-2 !important;
            border-color: $color-yellow-2 !important;
            color: $color-white !important;
        }
        &:disabled {
            background: $color-gray !important;
            border-color: $color-gray !important;
        }
    }
    &.btn-outline-warning {
        background: $color-white;
        border-color: $color-yellow;
        color: $color-yellow;
        &:hover, &:focus, &:active {
            background: $color-yellow-2 !important;
            border-color: $color-yellow-2 !important;
            color: $color-white !important;
        }
        &:disabled {
            background: $color-gray-dark !important;
            border-color: $color-gray-dark !important;
        }
    }
    &.btn-primary {
        background: $color-blue;
        border-color: $color-blue;
        color: $color-white;
        &:hover, &:focus, &:active {
            background: $color-blue-2 !important;
            border-color: $color-blue-2 !important;
            color: $color-white !important;
        }
        &:disabled {
            background: $color-gray !important;
            border-color: $color-gray !important;
        }
    }
    &.btn-outline-primary {
        background: $color-white;
        border-color: $color-blue;
        color: $color-blue;
        &:hover, &:focus, &:active {
            background: $color-blue-2 !important;
            border-color: $color-blue-2 !important;
            color: $color-white !important;
        }
        &:disabled {
            background: $color-gray-dark !important;
            border-color: $color-gray-dark !important;
        }
    }
    &.btn-property {
        font-size: 18px;
    }
    &.btn-balance {
        padding: 2px 10px;
        background-color: $color-red;
        color: $color-white;
        height: auto;
        &:hover {
            background-color: $color-red;
            color: $color-white;
        }
    }
    &.btn-red {
        padding: 10px;
        font-weight: 600;
        font-size: 16px;
        border-radius: 5px;
        width: 100%;
        display: block;
        text-align: center;
        &.white-btn {
            color: $color-red;
            background-color: $color-white;
        }
        &.theme-btn {
            background-color: $color-red;
            color: $color-white;
            border: unset;
        }
        &.border-btn {
            border: 1px solid $color-red;
            color: $color-red;
            background-color: $color-white;
        }
        &.fixed-button {
            position: fixed;
            bottom: 15px;
            margin: 0 15px;
            width: -webkit-fill-available;
            max-width: calc(600px - 30px);
            border: none;
        }
    }
    &.btn-info-pay {
        position: absolute;
        top: 6px;
        right: 1rem;
        z-index: 2;
        padding: 1.25rem 1rem;
    }
}