.alert-primary {
    color: #0055ff;
    background-color: #eaf1ff;
    border-color: #eaf1ff;
}
.alert-success {
    color: $color-dark;
    background-color: $color-white;
    border-color: $color-green-2;
}
.alert-warning {
    color: $color-dark;
    background-color: #fff6ec;
    border-color: $color-yellow;
}
.alert-info {
    color: #08a7c1;
    background-color: #effdff;
    border-color: #effdff;
}
.alert-danger {
    color: $color-dark;
    background-color: #fbeeee;
    border-color: $color-red;
}
.alert-dark {
    color: #3e4758;
    background-color: #eff4ff;
    border-color: #eff4ff;
}
.alert-secondary {
    color: #4b5d6f;
    background-color: #d6dfe8;
    border-color: #d6dfe8;
}
.alert p {
    margin: 0;
}