.agency-box {
    position: relative;
    display: flex;
    flex-direction: column;
    border: 1px solid $border-color;
    border-radius: $border-radius;
    background: $color-gray-light;
    margin-bottom: 15px;
    transition: .3s linear;
    &:hover {
        cursor: pointer;
        box-shadow: #{$box-shadow};
    }
    .right-button {
        position: absolute;
        left: 10px;
        top: 10px;
        span {
            padding: 4px 10px;
            background: $color-blue;
            border-radius: 50px;
            color: $color-white;
            font-size: 12px;
            &:hover {
                cursor: pointer;
            }
        }
    }
    .footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        border-top: 1px solid $border-color;
        .location {
            font-weight: 500;
            font-size: 12px;
            color: $color-dark-2;
            align-items: center;
        }
    }
    .content {
        padding: 0 10px 15px 10px;
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;
        text-align: center;
        .title {
            font-size: 18px;
            margin: 0 0 5px;
            line-height: 1.2;
        }
        .description {
            font-size: 16px;
            font-weight: 500;
            color: $color-dark-2;
        }
    }
    .thumb {
        width: 150px;
        height: 150px;
        padding: 10px;
        // border-radius: 50%;
        position: relative;
        margin: 20px auto 20px;
        border: 1px solid $color-gray-light;
        img {
            border-radius: $border-radius;
        }
    }
}
.agency-detail {
    .agency-info {
        border: 1px solid $color-gray-light;
        border-radius: $border-radius;
        overflow: hidden;
        position: relative;
        .agency-cover {
            background: $color-gray-light no-repeat;
            background-position-x: 0%;
            background-position-y: 0%;
            background-size: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            height: 120px;
            background-size: cover !important;
            background-position: center !important;
        }
        .agency-logo {
            position: relative;
            margin: -50px auto 20px;
            width: 120px;
            height: 120px;
            border-radius: 50%;
            border: 1px solid $color-gray-light;
            padding: 3px;
            img {
                border-radius: 100%;
                max-width: 100%;
                height: auto;
            }
        }
        .agency-content {
            margin: 20px;
            .agency-title {
                text-align: center;
                font-size: 20px;
                font-weight: 600;
                margin-bottom: 5px;
            }
            .agency-property {
                font-size: 16px;
                font-weight: 500;
                color: $color-dark-2;
                text-align: center;
                margin-bottom: 15px;
            }
            .agency-content-list {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                margin-bottom: 15px;
                .agency-content-list-icon {
                    width: 40px;
                    height: 40px;
                    background: transparent;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 15px;
                    color: $color-green;
                    margin-right: 10px;
                    border: 2px solid $color-gray-light;
                }
                .agency-content-list-text {
                    display: block;
                    position: relative;
                    width: auto;
                    h6 {
                        margin: 0;
                        font-size: 14px;
                        color: $color-dark;
                        text-transform: none;
                    }
                    p {
                        margin: 0;
                        font-size: 14px;
                        color: $color-dark;
                        a {
                            color: $color-dark;
                        }
                    }
                }
            }
        }
    }
    .agency-main-content {
        .agency-description {
            border: 1px solid $color-gray-light;
            border-radius: $border-radius;
            overflow: hidden;
            position: relative;
            padding: 15px;
            margin-bottom: 20px;
            h1 {
                color: $color-dark;
                font-weight: 600;
                font-size: 22px;
                text-transform: none;
                margin-bottom: 5px;
            }
            p {
                margin: 0;
                font-size: 14px;
                color: $color-dark;
            }
        }
        .agency-ad-type {
            margin-bottom: 20px;
            border-bottom: 1px solid $border-color;
            align-items: center;
            justify-content: space-between;
            ul {
                width: 100%;
                align-items: center;
                margin-bottom: 0;
                padding-left: 0;
                list-style: none;
                li {
                    width: auto;
                    cursor: pointer;
                    display: inline-block;
                    font-size: 14px;
                    user-select: none;
                    padding: 5px 10px;
                    transition: .3s linear;
                    &.active {
                        border-bottom: 2px solid $color-green;
                        a {
                            color: $color-green;
                        }
                    }
                    a {
                        color: $color-dark;
                        transition: .3s linear;
                    }
                    &:hover {
                        a {
                            color: $color-green;
                        }
                    }
                }
            }
        }
    }
}