
.top-bar {
    text-decoration: none;
    font-size: 14px;
    line-height: 20px;
    font-weight: normal;
    color: $color-dark;
    background-color: $color-gray-light;
    padding: 5px 0;

    .login-btn, .account-btn {
        font-weight: 600;
        color: $color-dark-2;
        border-bottom: 1px solid $color-dark-2;

        i {
            font-size: 15px;
        }

        &:hover {
            cursor: pointer;
        }
    }

    .announcement-text {
        margin: 0;
    }

    .text {
        color: $color-dark;
        margin-right: 20px;
    }

    .top-wrapper {
        .top-wrapper-btn {
            border: none;
            background: transparent;
            color: $color-dark;

            i {
                font-size: 15px;
            }

            .icon-dropdown {
                stroke-width: 1.5px;
            }
        }

        .top-dropdown-list {
            padding: 10px;

            .top-dropdown-list-item {
                padding: 4px;

                .top-dropdown-list-option {
                    color: $color-dark;

                    &:hover {
                        color: $color-red;
                    }
                }
            }
        }
        
    }
}

