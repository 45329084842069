

.hidden {
    display: none !important;
}

.uploaded-images {
    padding: 10px 0;
    display: inline-block;
}

.upload-image {
    float: left;
    width: 130px;
    position: relative;
    margin: 0 7px 10px;
    cursor: move;

    .thumbnail {
        width: 100%;
        height: 105px;
        padding: 3px;
        background: $color-white;
        /* text-align: center; */
        border: 1px solid $color-gray-light;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0.4rem;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    .image-actions {
        padding: 5px 0;
        text-align: center;
        display: flex;

        .action-button {
            height: 25px;
            width: 100%;
            background-color: $color-gray-light;
            border: 1px solid $color-gray-dark;
            float: left;
            margin-right: 5px;
            border-radius: 0.4rem;

            a {
                line-height: 24px;
                color: $color-dark;
            }

            &:first-child {
                background-color: $color-red;
                
                i {
                    color: $color-white;
                }

                &:hover {
                    background-color: $color-red-2;
                }
            }

            &:last-child {
                margin-right: 0;
            }

            &:hover {
                background-color: $color-gray-dark;
            }
        }

    }
}

.file-upload-btn {
    position: relative;
    padding: 5px 15px;
    color: $color-dark;
    font-size: 16px;
    background-color: $color-gray-light;
    border: 1px solid $color-dark;
    border-radius: 0.4rem;
    cursor: pointer;

    i {
        font-size: 16px;
    }
}
