
footer {
	font-weight: 500;
	position: relative;
    // border-top: 1px solid $color-gray-light;
    background-color: $color-gray-light;

    .footer-widget {
        padding: 30px 0;
        h4 {
            margin-bottom: 20px;
            font-weight: 600;
            font-size: 15px;
            color: $color-dark;
        }
        ul {
            padding: 0;
            margin: 0;
            li {
                list-style: none;
                margin-top: 15px;
                display: block;
                a {
                    color: $color-dark;
                    font-size: 16px;
                    transition: all .6s ease 0s;
                    &:hover {
                        color: $color-red;
                    }
                }
            }
        }
        p {
            color: $color-dark;
            font-size: 16px;
            transition: all .6s ease 0s;
        }
    }

    .footer-top {
        padding: 25px 0 15px 0;
        color: $color-dark;
        font-size: 14px;
        .footer-top-title {
            font-size: 20px;
            color: $color-dark;
            text-align: left;
            margin-bottom: 15px;
        }
        ul {
            margin-bottom: 20px;
            padding: 0;
            display: grid;
            grid-template-columns: repeat(8, 1fr);
            -moz-column-gap: 45px;
            column-gap: 45px;
            li {
                display: inline-block;
                margin-bottom: 5px;
                a {
                    display: block;
                    position: relative;
                    color: $color-dark;
                    transition: all .6s ease 0s;
                    &:hover {
                        color: $color-red;
                    }
                }
            }
        }
    }

    .footer-bottom {
        padding: 15px 0;
        color: $color-dark;
        font-size: 16px;
        border-top: 1px solid $color-white;
        i {
            color: $color-red;
        }
        nav {
            margin-bottom: 0;
            ul {
                margin-bottom: 0;
                li {
                    display: inline-block;
                    a {
                        display: block;
                        padding: 0 10px;
                        position: relative;
                        color: $color-dark;
                        transition: all .6s ease 0s;
                        &:hover {
                            color: $color-red;
                        }
                        &:after {
                            content: "|";
                            width: 1px;
                            height: 22px;
                            position: absolute;
                            right: 0;
                            top: 0;
                            color: $color-gray-dark;
                            display: block;
                        }
                    }
                    &:last-child {
                        a {
                            &::after {
                                display: none
                            }
                        }
                    }
                }
            }
        }
    }
}
