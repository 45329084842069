.form-control {
    height: 40px;
    font-size: 14px;
    box-shadow: none;
    border: 1px solid $border-color;
	background-clip: initial;
    &.xs {
        height: 30px;
        font-size: 10px;
    }
    &.sm {
        height: 35px;
        font-size: 12px;
    }
    &.md {
        height: 45px;
        font-size: 13px;
    }
    &.lg {
        height: 60px;
        font-size: 15px;
    }
}
textarea {
    &.form-control {
        height: 150px !important;
    }
}
.form-group {
    margin-bottom: 15px;
    label {
        margin-bottom: 5px;
        font-size: 16px;
        font-weight: 500;
    }
    .form-control {
        height: 40px;
    }
}
span {
    &.error {
        display: block;
        color: #e55151 !important;
    }
}