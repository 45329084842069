.breadcrumb-nav {
    padding: 5px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-size: cover !important;
    background-color: $color-gray-light;
    .breadcrumb {
        margin-top: 5px;
        margin-bottom: 5px;
        font-size: 16px;
        .breadcrumb-item {
            color: $color-dark;
            display: flex;
            align-items: center;
            text-transform: none;
            a {
                color: $color-dark;
            }
            &+&::before {
                content: "/";
                color: $color-dark;
            }
            &.active {
                color: $color-red;
            }
        }
    }
}