.notifications {
    ul {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        padding-left: 0;
        li {
            width: 100%;
            padding: 10px;
            background: $color-white;
            border-radius: $border-radius;
            border: 1px solid $border-color;
            margin-bottom: 10px;
            position: relative;
            overflow: hidden;
            .content {
                width: 100%;
                font-size: 14px;
                .date {
                    margin-bottom: 5px;
                    color: $color-dark;
                    text-decoration: underline;
                }
                p {
                    color: $color-dark-2;
                    line-height: 1.5;
                    margin-bottom: 0;
                }
            }
        }
    }
}
.notification-count {
    position: absolute;
    right: 10px;
    width: 18px;
    height: 18px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: $color-dark-2;
    border-radius: 50%;
    font-size: 12px;
    font-weight: 600 !important;
    color: $color-white;
    &.red {
        background: $color-red;
    }
    &.green {
        background: $color-green;
    }
    &.black {
        background: $color-dark;
    }
}