.auth-title {
    margin-bottom: 0px;

    h4 {
        font-weight: 600;
        color: $color-dark;
        text-transform: none;
    }
}
.auth-divider {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    position: relative;
    z-index: 0;

    &::after {
        content: "";
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        left: 0;
        width: 100%;
        height: 1px;
        border-top: 1px dashed $color-gray-light;
        z-index: -1;
    }

    label {
        font-weight: 400;
        font-size: 14px;
        color: $color-dark;
        background-color: $color-white;
        padding-inline: 14px;
    }
}

.social-login {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 15px;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;

    li {
        width: 100%;

        a {
            border-radius: 5px;
            padding: 10px;
            color: $color-white;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            gap: 4px;

            &.google-bg {
                background-color: #c43218;
            }

            &.fb-bg {
                background-color: #2d4996;
            }

            i {
                font-size: 14px;
            }
        }
    }
}

.content-flex {
    display: flex;
    justify-content: space-between;
    width: 100%;
}