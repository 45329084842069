.sidebar {
    .profile {
        text-align: left;
        margin: 0 0 1rem;
        img {
            max-width: 100px;
            border-radius: 50%;
            margin-bottom: 8px;
        }
        h4 {
            font-size: 18px;
        }
    }
    .social {
        position: relative;
        margin: 0;
        padding: 0;
        ul {
            padding: 0;
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            li {
                list-style: none;
                position: relative;
                margin: 0 0.5rem;
                a {
                    width: 35px;
                    height: 35px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                }
            }
        }
    }
    .menu {
        ul {
            padding: 0;
            margin: 0;
            li {
                list-style: none;
                padding: 5px 0px;
                margin: 0;
                display: block;
                width: 100%;
                &:first-child {
                    padding-top: 0;
                }
                &.active {
                    a {
                        background: $color-white;
                        border-color: $color-green;
                    }
                }
                a {
                    padding: 1rem 1rem;
                    color: $color-dark;
                    border-radius: 0.4rem;
                    font-size: 14px;
                    display: inline-flex;
                    align-items: center;
                    width: 100%;
                    font-weight: 500;
                    position: relative;
                    transition: all ease 0.4s;
                    background: $color-gray-light;
                    border: 1px solid $border-color;
                    &:hover, &:focus {
                        background: $color-white;
                        border-color: $color-green;
                    }
                    i {
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}
.dashboard-main {
    .main-box {
        padding: 15px;
        background: $color-gray-light;
        border: 1px solid $border-color;
        border-radius: $border-radius;
        color: $color-dark-2;
        margin-bottom: 20px;
        .box-title {
            margin-bottom: 5px;
            padding-bottom: 5px;
            font-size: 18px;
            border-bottom: 1px solid $border-color;
        }
        .box-content {
            padding: 20px 0;
            &.properties {
                padding: 15px 0;
            }
            .big-text {
                font-size: 24px;
                font-weight: 600;
            }
            ul {
                padding: 0;
                margin: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                li {
                    text-align: center;
                    list-style: none;
                    position: relative;
                    padding: 0 10px;
                    border-right: 1px solid $border-color;
                    &:first-child {
                        padding-left: 0;
                    }
                    &:last-child {
                        border-right: 0;
                        padding-right: 0;
                    }
                    .p-title {
                        text-decoration: underline;
                    }
                    p {
                        font-weight: 600;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    .dashboard-action-menu {
        margin-bottom: 10px;
        border-bottom: 1px solid $border-color;
        align-items: center;
        justify-content: space-between;
        ul {
            width: 100%;
            align-items: center;
            margin-bottom: 0;
            padding-left: 0;
            list-style: none;
            li {
                width: auto;
                cursor: pointer;
                display: inline-block;
                font-size: 14px;
                -webkit-user-select: none;
                user-select: none;
                padding: 5px 10px;
                transition: .3s linear;
                &.active {
                    border-bottom: 2px solid $color-green;
                    a {
                        color: $color-green;
                    }
                }
                a {
                    color: $color-dark;
                    transition: .3s linear;
                }
                &:hover {
                    a {
                        color: $color-green;
                    }
                }
            }
        }
    }
}
.dashboard-profile-form {
    textarea {
        height: 100px !important;
    }
}
.dashboard-property-menu {
    margin-bottom: 10px;
    border-bottom: 1px solid $border-color;
    align-items: center;
    justify-content: space-between;
    ul {
        width: 100%;
        align-items: center;
        margin-bottom: 0;
        padding-left: 0;
        list-style: none;
        li {
            width: auto;
            cursor: pointer;
            display: inline-block;
            font-size: 14px;
            -webkit-user-select: none;
            user-select: none;
            padding: 5px 10px;
            transition: .3s linear;
            &.active {
                border-bottom: 2px solid $color-green;
                a {
                    color: $color-green;
                }
            }
            a {
                color: $color-dark;
                transition: .3s linear;
            }
            &:hover {
                a {
                    color: $color-green;
                }
            }
        }
    }
}
.dashboard-property-type-menu {
    margin-bottom: 10px;
    align-items: center;
    justify-content: space-between;
    ul {
        width: 100%;
        align-items: center;
        margin-bottom: 0;
        padding-left: 0;
        list-style: none;
        li {
            width: auto;
            cursor: pointer;
            display: inline-block;
            font-size: 14px;
            -webkit-user-select: none;
            user-select: none;
            padding: 5px 10px;
            transition: .3s linear;
            border-radius: 10px;
            border: 1px solid $color-green;
            background-color: $color-white;
            a {
                color: $color-green;
                transition: .3s linear;
            }
            &.active {
                border-color: $color-green;
                background-color: $color-green;
                a {
                    color: $color-white;
                }
            }
            &:hover {
                border-color: $color-green;
                background-color: $color-green;
                a {
                    color: $color-white;
                }
            }
        }
    }

}
.dashboard-section-title {
    .title {
        font-size: 20px;
        margin-bottom: 15px;
        font-weight: 600;
    }
}
.dashboard-property-list {
    tbody {
        tr {
            td {
                padding: 10px;
            }
        }
    }
    .single-property-list {
        padding: 10px;
        margin-top: 5px;
        .single-property-image {
            max-width: 60px;
            border-radius: $border-radius;
            img {
                border-radius: $border-radius;
                width: 100%;
                height: 60px;
                -o-object-fit: cover;
                object-fit: cover;
            }
        }
        .single-property-title {
            .title {
                color: $color-dark;
                font-size: 14px;
            }
            .number {
                font-size: 14px;
                span {
                    font-weight: 600;
                }
            }
        }
        .single-property-price {
            font-size: 14px;
            span {
                font-size: 12px;
            }
        }
        .single-property-action {
            text-align: right;
            a, button {
                width: 36px;
                height: 36px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                border: 1px solid $border-color;
                background-color: $color-gray-light;
                font-size: 14px;
                border-radius: $border-radius;
                color: $color-dark;
                margin: 2px;
                transition: all ease 0.4s;
                i {
                    font-size: 18px;
                    transition: all ease 0.4s;
                }
                &:hover {
                    background-color: $color-white;
                    border-color: $color-red;
                    i {
                        color: $color-red;
                    }
                }
            }
        }
    }
}