.slick-slider {
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    position: relative;
    display: block;
    .slick-list {
        overflow: hidden;
        margin: 0;
        padding: 0;
        position: relative;
        display: block;
        &:focus {
            outline: 0;
        }
        &.dragging {
            cursor: pointer;
            cursor: hand;
        }
        .slick-track {
            position: relative;
            display: block;
            top: 0;
            left: 0;
            &:after, &:before {
                display: table;
                content: "";
            }
            &:after {
                clear: both;
            }
        }
    }
    .slick-list, .slick-track {
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}
.slick-loading {
    .slick-slide, .slick-track {
        visibility: hidden;
    }
    .slick-list {
        background: $color-white;
    }
}
.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
    img {
        display: block;
    }
    &.slick-loading {
        img {
            display: none;
        }
    }
    &.dragging {
        img {
            pointer-events: none;
        }
    }
}
.slick-initialized {
    .slick-slide {
        display: block;
    }
}
.slick-vertical {
    .slick-slide {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow {
    &.slick-hidden {
        display: none;
    }
}
.slick-next, .slick-prev {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: 0;
    background: 0 0;
    &:focus, &:hover, &:active {
        color: transparent;
        outline: 0;
        background: 0 0;
        &:before {
            opacity: 1;
        }
    }
    &.slick-disabled, &.slick-disabled {
        &:before, &:before {
            opacity: 0.25;
        }
    }
    &:before, &:before {
        font-family: 'remixicon';
        font-size: 20px;
        line-height: 1;
        opacity: 0.75;
        color: $color-white;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    &:hover, &:focus, &:active {
        background-color: $color-red;
        &:before {
            color: $color-white;
        }
    }
}
.slick-prev {
    left: 20px;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $color-white;
    border-radius: 50%;
	z-index:1;
    transition: all ease 0.4s;	
    &:before {
        content: "\ea60";
        color: $color-red;
    }
}
.slick-next {
    right: 20px;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $color-white;
    border-radius: 50%;
	z-index:1;
    transition: all ease 0.4s;
    &:before {
        content: "\ea6c";
        color: $color-red;
    }
}
.slick-dotted {
    &.slick-slider {
        margin-bottom: 30px;
    }
}
.slick-dots {
    position: absolute;
    bottom: -25px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    li {
        position: relative;
        display: inline-block;
        width: 20px;
        height: 20px;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;
        button {
            font-size: 0;
            line-height: 0;
            display: block;
            width: 20px;
            height: 20px;
            padding: 5px;
            cursor: pointer;
            color: transparent;
            border: 0;
            outline: 0;
            background: 0 0;
            &:focus, &:hover {
                outline: 0;
                &:before, &:before {
                    opacity: 1;
                }
            }
            &:before {
                font-family: 'remixicon';
                font-size: 6px;
                line-height: 20px;
                position: absolute;
                top: 0;
                left: 0;
                width: 20px;
                height: 20px;
                content: "\f3c1";
                text-align: center;
                opacity: 0.25;
                color: $color-dark;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
        }
        &.slick-active {
            button {
                &:before {
                    opacity: 0.75;
                    color: $color-dark;
                }
            }
        }
    }
}