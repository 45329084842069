
.property-form, .advanced-search-form {
    p {
        margin: 0;
    }
    h1 {
        font-size: 28px;
        font-weight: 600;
        color: $color-dark;
        margin-bottom: 20px;
        text-transform: unset;
    }
    hr {
        margin: 10px 0;
    }
    .form-group {
        margin-bottom: 10px;
    }
    .form-check {
        margin-top: 8px;
        .form-check-input {
            width: 18px;
            height: 18px;
            margin-right: 10px;
            border: 1px solid $color-dark-2;
            &:checked {
                background-color: $color-red;
                border-color: $color-red;
            }
        }
    }
    .single-checkbox {
        .form-check {
            margin-top: 29px;
            padding-left: 0;
        }
        label {
            margin-bottom: 0;
            width: 100%;
            float: left;
            cursor: pointer;
            padding: 0px 0.6em;
            box-sizing: border-box;
            background: $color-gray-light;
            transition: all 0.5s ease 0s;
            border-radius: $border-radius;
            border: 1px solid $color-gray-dark;
        }
        input[type="checkbox"] {
            display: none;
            & + label {
                line-height: 38.4px;
                &::before {
                    content: "";
                    width: 21px;
                    height: 21px;
                    float: left;
                    margin-right: 0.5em;
                    border: 2px solid $color-gray-dark;
                    background: $color-white;
                    margin-top: 9px;
                }
            }
            &:disabled {
                & + label {
                    color: $color-gray !important;
                    cursor: not-allowed;
                }
            }
            &:checked {
                &:disabled {
                    & + label {
                        background: $color-gray;
                        color: $color-white !important;
                        &::after {
                            border-color: $color-gray;
                        }
                        &::before {
                            border-color: $border-color;
                        }
                    }
                }
                & + label {
                    background: $color-gray-dark;
                    &::after {
                        content: "";
                        width: 16px;
                        height: 8px;
                        border: 4px solid $color-red;
                        float: left;
                        margin-left: -26.5px;
                        border-right: 0;
                        border-top: 0;
                        margin-top: 14px;
                        transform: rotate(-55deg);
                    }
                    &::before {
                        border-color: $color-gray-dark;
                    }
                }
            }
        }
    }
    .form-select {
        height: 40px;
    }
    textarea {
        height: 130px !important;
    }
    .divider {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 2rem 0 2rem;
        font-weight: 500;
        font-size: 14px;
        color: $color-gray-dark;
    }
    .input-group-text {
        // font-size: 20px;
        border-radius: 0 .375rem .375rem 0;
        height: 40px;
    }
    .location-area {
        background: url(https://rahatemlak.az/images/map.jpg) $color-white no-repeat center;
        background-size: auto;
        position: relative;
        background-size: cover;
        border-radius: 0.4rem;
        overflow: hidden;
        width: 100%;
        height: 160px;
        cursor: pointer;
        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $color-gray-light;
            z-index: 1;
            transition: opacity 0.2s ease-in-out;
            opacity: 0.4;
        }
        .map-btn {
            position: relative;
            -moz-column-gap: 10px;
            column-gap: 10px;
            padding: 10px 15px;
            color: $color-dark;
            font-size: 15px;
            background-color: $color-white;
            border: 1px solid $color-dark;
            border-radius: 0.4rem;
            z-index: 1;
            margin: 55px;
        }
        .static-map {
            position: relative;
            z-index: 2;
        }
    }
    .landmarks-list {
        ul {
            display: flex;
            flex-wrap: wrap;
            margin: 0;
            padding: 0;
            list-style: none;
            li {
                position: relative;
                display: flex;
                flex: none;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                margin-top: 5px;
                margin-right: 5px;
                padding: 5px 10px;
                font-size: 14px;
                color: $color-dark;
                border: 1px solid $color-gray-dark;
                border-radius: 55px;
                background-color: $color-gray-dark;
                box-sizing: border-box;
                span {
                    &.pin {
                        margin-right: 5px;
                        color: $color-green;
                    }
                    &.remove-landmark {
                        margin-left: 10px;
                        cursor: pointer;
                        border-radius: 50%;
                        transition: background-color 0.2s linear;
                        background-color: $color-white;
                        padding: 0 4px;
                    }
                }
            }
        }
    }
    .info-text {
        p {
            font-size: 14px;
            margin: 10px 0;
            i {
                color: $color-red;
            }
            &.agree {
                text-decoration: underline;

                a {
                    color: $color-blue;
                }
            }
        }
    }
    .property-rules {
        .title {
            text-align: center;
        }
        p {
            margin-bottom: 15px;
        }
    }
}
.custom-box {
    position: relative;
    display: flex;
    width: 100%;
    .box {
        display: flex;
        flex-direction: column;
        flex: 0 0 33.333333%;
        width: 33.333333%;
        padding: 1.5rem;
        text-align: center;
        .icon {
            font-size: 44px;
            color: $color-green;
            margin-bottom: 0.5rem;
        }
        .caption {
            p {
                margin-bottom: 0;
                font-weight: 500;
                font-size: 16px;
                color: $color-dark;
            }
        }
    }
}
.property-info {
    .info-icon {
        text-align: center;
        margin-bottom: 5px;
        i {
            font-size: 80px;
            color: $color-green;
        }
        &.danger {
            i {
                color: $color-red;
            }
        }
    }
    .info-content {
        text-align: center;
        margin-bottom: 20px;
        h3 {
            text-transform: unset;
        }
        p {
            font-size: 16px;
            &.payment-amount {
                text-decoration: underline;
            }
        }
    }
    .info-button {
        text-align: center;
        margin-bottom: 20px;
        .btn {
            height: 40px !important;
            font-size: 16px;
            padding: 10px 12px;
            border-radius: 0.4rem;
            margin: 0 5px;
        }
    }
}
.property-sorting-area {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    .sorting-type {
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        span {
            font-size: 14px;
            margin-right: 10px;
        }
        ul {
            align-items: center;
            border: 1px solid #{$border-color};
            border-radius: #{$border-radius};
            margin-bottom: 0;
            padding-left: 0;
            padding: 4px 6px;
            list-style: none;
            li {
                cursor: pointer;
                display: inline-block;
                background-color: transparent;
                border: none;
                font-size: 14px;
                -webkit-user-select: none;
                user-select: none;
                padding: 5px 10px;
                border-radius: #{$border-radius};
                transition: .3s linear;
                &.active {
                    color: #{$color-dark};
                    background-color: #{$color-gray-dark};
                    border-radius: #{$border-radius};
                }
                a {
                    color: #{$color-dark};
                    transition: .3s linear;
                }
                &:hover {
                    background-color: #{$color-gray-dark};
                    border-radius: #{$border-radius};
                    a {
                        color: #{$color-dark};
                    }
                }
            }
        }
    }
    .sorting-by {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        select {
            border-radius: #{$border-radius};
            border: 1px solid #{$border-color};
            font-size: 14px;
            height: 36.6px;
            cursor: pointer;
        }
    }
}
.property-card {
    background: #{$color-white};
    border-radius: #{$border-radius};
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 1.5rem;
    transition: .3s linear;
    &:hover {
        cursor: pointer;
        box-shadow: #{$box-shadow};
    }
    .property-card-features {
        position: absolute;
        width: 100%;
        left: 12px;
        top: 12px;
        z-index: 1;
        span {
            color: #{$color-white};
            font-size: 14px;
            margin-right: 3px;
            border-radius: 50%;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            padding: 2px 6px;
            height: 26px;
            &.bg-document {
                background: #{$color-green};
            }
            &.bg-mortgage {
                background: #{$color-blue};
            }
            &.bg-repair {
                background: #{$color-red};
            }
            &::before {
                position: absolute;
                background-color: #{$color-white};
                color: #{$color-dark};
                padding: 2px 6px;
                border-radius: #{$border-radius};
                content: attr(data-info);
                font-size: 12px;
                top: 32px;
                margin-left: 40px;
                text-align: center;
                min-width: 80px;
                width: auto;
                z-index: 1000;
                display: none;
            }
            &::after {
                position: absolute;
                top: 25px;
                width: 0;
                height: 0;
                content: "";
                border-top: 5px solid transparent;
                border-bottom: 5px solid transparent;
                border-left: 5px solid #{$color-white};
                z-index: 1000;
                transform: rotate(-90deg);
                display: none;
            }
            &:hover {
                &::before, &::after {
                    display: block;
                }
                &.bg-document {
                    &::before {
                        color: #{$color-white};
                        background-color: #{$color-green};
                    }
                    &::after {
                        border-left: 5px solid #{$color-green};
                    }
                }
                &.bg-mortgage {
                    &::before {
                        color: #{$color-white};
                        background-color: #{$color-blue};
                    }
                    &::after {
                        border-left: 5px solid #{$color-blue};
                    }
                }
                &.bg-repair {
                    &::before {
                        color: #{$color-white};
                        background-color: #{$color-red};
                    }
                    &::after {
                        border-left: 5px solid #{$color-red};
                    }
                }
            }
        }
    }
    .property-card-thumb {
        display: flex;
        flex-direction: column;
        position: relative;
        .thumb-image-slide {
            a {
                position: relative;
                display: block;
                height: auto;
                width: 100%;
                &:before {
                    content: "";
                    position: absolute;
                    background: #020406;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    opacity: 0.2;
                }
            }
            .slick-prev {
                left: 0;
                z-index: 1;
                width: 30px;
                height: 60px;
                border-radius:0px 100px 100px 0px;
                color: #ffffff !important;
                opacity: 1 !important;
            }
            .slick-next {
                right: 0;
                z-index: 1;
                width: 30px;
                height: 60px;
                border-radius:100px 0px 0px 100px;
                color: #ffffff !important;
                opacity: 1 !important;
            }
            .image-click {
                transition:all ease 0.4s;
                &:hover, &:focus {
                    .slick-next, .slick-prev {
                        display: block !important;
                    }
                }
                img {
                    width: 100%;
                    height: 200px;
                    object-fit: cover;
                }
                .slick-next, .slick-prev {
                    display: none !important;
                }
            }
        }
        .author-type {
            position: absolute;
            left: 12px;
            bottom: 12px;
            z-index: 1;
            font-size: 12px;
            padding: 3px 8px;
            border-radius: #{$border-radius};
            border: none;
            color: #{$color-white};
            background: #{$color-red-2};
        }
        .paid-services {
            position: absolute;
            right: 12px;
            bottom: 12px;
            z-index: 1;
            font-size: 12px;
            padding: 3px 8px;
            border-radius: #{$border-radius};
            border: none;
            color: #{$color-red-2};
            background: #{$color-white};
            .vip {
                padding: 0 2px;
                i {
                    color: #{$color-yellow};
                }
            }
            .premium {
                padding: 0 2px;
                i {
                    color: #{$color-red};
                }
            }
        }
    }
    .property-card-content {
        display: flex;
        flex-direction: column;
        position: relative;
        border: 1px solid #{$color-gray-light};
        border-top: none;
        border-radius: 0 0 #{$border-radius} #{$border-radius};
        padding: 10px 10px 8px 10px;
        .property-card-top {
            .amount {
                margin: auto;
                font-size: 18px;
                font-weight: 600;
                margin-bottom: 0px;
                position: relative;
                line-height: 1.2;
                color: #{$color-dark};
                .interval {
                    font-size: 12px;
                    color: #{$color-dark-2};
                    margin-left: 2px;
                }
            }
        }
        .property-card-footer {
            color: #{$color-dark-2};
            position: relative;
            display: flex;
            flex-direction: column;
            margin-top: 3px;
            width: 100%;
            .name {
                font-size: 14px;
                font-weight: 600;
            }
            .footer-info {
                margin-top: 3px;
                font-size: 12px;
                position: relative;
                display: flex;
                justify-content: space-between;
                width: 100%;
                .date {
                    display: inline-flex;
                    align-items: center;
                }
                .location {
                    display: inline-flex;
                    align-items: center;
                }
            }
        }
        .property-card-middle {
            display: flex;
            width: 100%;
            position: relative;
            margin-top: 3px;
            ul {
                padding: 0;
                margin: 0;
                display: flex;
                align-items: center;
                li {
                    font-size: 13px;
                    display: inline-flex;
                    align-items:center;
                    list-style: none;
                    margin-right: 15px;
                    color: #{$color-dark-2};
                    &:last-child {
                        margin: 0;
                    }
                    span {
                        margin-left: 5px;
                    }
                }
            }
        }
    }
}
.property-content {
    border-top: 1px solid $color-gray-light;
    margin-top: 20px;
    padding-top: 10px;
    h2 {
        color: $color-dark;
        font-weight: 600;
        font-size: 24px;
        text-transform: none;
    }
    p {
        font-size: 14px;
        color: $color-dark-2;
    }
}
.property-detail {
    .detail-title {
        margin-bottom: 20px;
        h1 {
            font-size: 24px;
            color: $color-dark;
            text-transform: unset;
        }
    }
    .detail-card {
        margin-bottom: 25px;
        .detail-card-title {
            padding-bottom: 10px;
            margin-bottom: 20px;
            border-bottom: 1px solid $border-color;
            h3 {
                position: relative;
                display: initial;
                font-size: 18px;
                line-height: 28px;
                text-transform: unset;
                &::before {
                    content: "";
                    width: 100%;
                    height: 2px;
                    background-color: $color-green;
                    position: absolute;
                    bottom: -15px;
                }
            }
        }
        .overview-box {
            font-size: 14px;
            position: relative;
            display: flex;
            width: 100%;
            margin-bottom: 20px;
            .overview-box-icon {
                border: 1px solid $border-color;
                border-radius: $border-radius;
                margin-right: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 48px;
                height: 48px;
                transition: all ease 0.5s;
                i {
                    font-size: 24px;
                    color: $color-dark-2;
                    transition: all ease 0.5s;
                }
            }
            .overview-box-content {
                align-items: center;
                justify-content: start;
                .overview-box-content-title {
                    color: $color-dark;
                    font-size: 16px;
                }
                .overview-box-content-text {
                    font-weight: 600;
                }
            }
            &:hover {
                .overview-box-icon {
                    background-color: $color-red;
                    border-color: $color-red;
                    i {
                        color: $color-white;
                    }
                }
            }
        }
        .text-box {
            font-size: 14px;
            position: relative;
            color: $color-dark;
        }
        .location-card {
            font-size: 14px;
            position: relative;
            color: $color-dark;
            .location-box {
                font-size: 14px;
                position: relative;
                display: flex;
                width: 100%;
                margin-bottom: 10px;
                align-items: center;
                .location-box-title {
                    color: $color-dark;
                    font-size: 14px;
                    font-weight: 600;
                    width: 25%;
                }
                .location-box-text {
                    width: 75%;
                }
            }
            .landmarks {
                ul {
                    display: flex;
                    flex-wrap: wrap;
                    margin: 0;
                    padding: 0;
                    list-style: none;
                    li {
                        position: relative;
                        display: flex;
                        flex: none;
                        flex-wrap: wrap;
                        justify-content: center;
                        align-items: center;
                        margin-top: 5px;
                        margin-right: 5px;
                        padding: 5px 10px;
                        font-size: 14px;
                        color: $color-dark;
                        border: 1px solid $color-gray-dark;
                        border-radius: 55px;
                        background-color: $color-gray-dark;
                        box-sizing: border-box;
                        a {
                            color: $color-dark;
                        }
                        span {
                            &.pin {
                                margin-right: 5px;
                                color: $color-green;
                            }
                        }
                    }
                }
            }
        }
    }
    .map-area {
        background: url(https://rahatemlak.az/images/map.jpg) $color-white no-repeat center;
        background-size: auto;
        background-size: auto;
        position: relative;
        background-size: cover;
        border-radius: 0.4rem;
        overflow: hidden;
        width: 100%;
        height: 120px;
        cursor: pointer;
        margin-bottom: 10px;
        .map-btn {
            position: relative;
            -moz-column-gap: 10px;
            column-gap: 10px;
            padding: 10px;
            color: $color-dark;
            font-size: 14px;
            background-color: $color-white;
            border: 1px solid $color-dark;
            border-radius: 0.4rem;
            z-index: 1;
            margin: 38px;
            text-align: center;
        }
    }
}
// random links
.random-links {
    ul {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
        list-style: none;
        li {
            position: relative;
            display: flex;
            flex: none;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            margin-top: 5px;
            margin-right: 5px;
            border: 1px solid $color-gray-dark;
            border-radius: 55px;
            background-color: $color-white;
            box-sizing: border-box;
            -webkit-transition: all ease 0.4s;
            -o-transition: all ease 0.4s;
            transition: all ease 0.4s;
            a {
                color: $color-dark;
                padding: 5px 10px;
                font-size: 14px;
                -webkit-transition: all ease 0.4s;
                -o-transition: all ease 0.4s;
                transition: all ease 0.4s;
            }
            &:hover {
                background-color: $color-green;
                a {
                    color: $color-white;
                }
            }
        }
    }
}
.service-box {
    position: relative;
    display: flex;
    width: 100%;
    background: $color-gray-light;
    border: 1px solid $border-color;
    border-radius: $border-radius;
    color: $color-dark-2;
    height: 40px;
    font-size: 16px;
    margin-bottom: 5px;
    align-items: center;
    justify-content: center;
    padding: 10px;
    cursor: pointer;
    -webkit-transition: all ease 0.4s;
    -o-transition: all ease 0.4s;
    transition: all ease 0.4s;
    &:hover, &:focus, &:active {
        background: $color-white;
        border-color: $color-green-2;
        color: $color-dark-2;
    }
    .service-box-icon {
        margin-right: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        i {
            &.forward {
                color: $color-blue
            }
            &.vip {
                color: $color-yellow;
            }
            &.premium {
                color: $color-red;
            }
            &.ads {
                color: $color-green;
            }
        }
    }
    .service-box-content {
        .service-box-content-title {
            font-size: 14px;
            span {
                font-size: 12px;
            }
        }
    }
}
.property-sidebar {
    .sidebar-top {
        font-size: 16px;
        position: relative;
        display: flex;
        width: 100%;
        margin-top: 6px;
        margin-bottom: 21px;
        align-items: center;
        color: $color-dark;
        justify-content: space-between;
        .add-report {
            span {
                transition: all ease 0.4s;
                &:hover {
                    cursor: pointer;
                    color: $color-red;
                }
            }
        }
    }
    .sidebar-card {
        border: 1px solid $border-color;
        border-radius: $border-radius;
        background-color: $color-white;
        padding: 20px;
        margin-bottom: 20px;
        &.none-border-padding {
            padding: 0;
            border: none;
        }
        .card-price {
            border-bottom: 1px solid $border-color;
            margin-bottom: 20px;
            padding-bottom: 20px;
            .price-value {
                font-weight: 700;
                font-size: 24px;
            }
            .price-part {
                font-size: 14px;
            }
        }
        .card-detail {
            .detail-list {
                font-size: 14px;
                position: relative;
                display: flex;
                width: 100%;
                margin-bottom: 10px;
                align-items: center;
                color: $color-dark;
                justify-content: space-between;
                .detail-list-title {
                    text-decoration: underline;
                }
                .detail-list-text {
                    &.text-bold {
                        font-weight: 600;
                    }
                }
            }
        }
        .card-author {
            font-size: 14px;
            position: relative;
            display: flex;
            width: 100%;
            margin-bottom: 20px;
            align-items: center;
            color: $color-dark;
            .author-image {
                margin-right: 15px;
                background-color: $color-gray-light;
                height: 50px;
                width: 50px;
                border-radius: 50%;
                border-color: 1px solid $border-color;
                display: flex;
                align-items: center;
                justify-content: center;
                i {
                    font-size: 26px;
                    color: $color-dark-2;
                }
            }
            .author-content {
                .author-content-name {
                    font-weight: 600;
                }
            }
        }
        .card-contact {
            .btn-contact {
                background: $color-green;
                border-color: $color-green;
                color: $color-white;
                height: 45px;
                font-size: 16px;
                margin-bottom: 5px;
                i {
                    font-size: 16px;
                    margin-right: 5px;
                }
                &:hover, &:focus, &:active{
                    background: $color-green-2;
                    border-color: $color-green-2;
                }
            }
            .phone-help {
                color: $color-dark-2;
                font-size: 12px;
            }
        }
        .card-agency {
            border-top: 1px solid $border-color;
            padding-top: 15px;
            margin-top: 15px;
            .card-agency-title {
                font-size: 14px;
                position: relative;
                display: flex;
                width: 100%;
                margin-bottom: 5px;
                .card-agency-logo {
                    margin-right: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 45px;
                    height: 45px;
                }
                .card-agency-name {
                    color: $color-dark;
                    h6 {
                        font-weight: 600;
                        font-size: 18px;
                        text-transform: unset;
                        margin-bottom: 3px;
                    }
                    p {
                        color: $color-dark-2;
                        margin-bottom: 0px;
                    }
                }
            }
            .card-agency-description {
                p {
                    color: $color-dark-2;
                    margin-bottom: 0px;
                }
            }
            .card-agency-contact {
                border-top: 1px solid $border-color;
                padding-top: 15px;
                margin-top: 15px;
                p {
                    color: $color-dark-2;
                    margin-bottom: 3px;
                    i {
                        color: $color-red;
                    }
                }
                .btn {
                    margin-top: 10px;
                }
            }
        }
    }
}

// .slick-slide {
//     height: inherit;
//     padding-left: 15px;
//     padding-right: 15px;
//     position: relative;

//     .box {
//         background-color: transparent !important;
//         font-size: .875rem;
//       }
//   }

// .slick-slider img {
//     max-width: 100%;
//     width: auto;
//     height: 100% !important;
//     width: 100% !important;
// }

.detail-images {
    position: relative;
    .slider {
        &.property-images-list {
            max-height: 480px;
            overflow: hidden;
            padding: 0;
            .property-image {
                cursor: pointer;
                position: relative;
                overflow: hidden;
                height: 480px !important;
                text-align: center;
                border-radius: 7px;
                background-color: rgba(0,0,0,0.9);
                -webkit-backdrop-filter: blur(20px);
                backdrop-filter: blur(20px);
                .image-background {
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    background-repeat: no-repeat;
                    background-position: 50%;
                    background-size: 150%;
                    background-color: rgba(0,0,0,0.4);
                    filter: blur(20px) brightness(0.6);
                }
                img {
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    max-height: 100%;
                    max-width: 100%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    filter: drop-shadow(0 4px 4px rgba(0,0,0,0.25));
                    -o-object-fit: contain;
                    object-fit: contain;
                }
            }
        }
        &.property-images-nav {
            padding: 10px 0;
            .property-image-thumbnail {
                padding: 5px;
                height: 100% !important;
                border-radius: $border-radius;
                -webkit-transition: all ease 0.4s;
                -o-transition: all ease 0.4s;
                transition: all ease 0.4s;
                margin-bottom: 5px;
                &:hover {
                    background-color: $color-gray-dark !important;
                    cursor: pointer;
                }
                img {
                    -o-object-fit: cover;
                    object-fit: cover;
                    height: 70px;
                    width: 100% !important;
                    max-width: 100%;
                    border-radius: $border-radius;
                }
            }
        }
        &.property-images-count {
            position: absolute;
            padding: 5px 10px;
            font-size: 12px;
            line-height: 14px;
            color: $color-white;
            background-color: rgba(0,0,0,0.4);
            border-radius: 15px;
            margin: -30px auto;
        }
    }

}
